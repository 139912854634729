import React, { useEffect } from "react";
import Layout from "../components/layout";
import { useAuth0 } from "@auth0/auth0-react";

const Logout = () => {
	const { logout } = useAuth0();

	useEffect(() => {
		const isBrowser = () => typeof window !== "undefined";
		if (isBrowser()) {
			logout({ returnTo: window.location.origin });
		}
	}, [logout]);

	return (
		<Layout title="Logout">
			<h1>Logging you out...</h1>
		</Layout>
	);
};

export default Logout;